<template>
  <div>
    <vue-headful
      title="Questionnaire: create or load template | Octoa"
    />
    <v-inner-header
      :is-saving.sync="isSaving"
      :is-saved.sync="isSaved"
      :redirect="`/projects/${projectId}`"
    />
    <div class="max-w-lg mx-auto pt-24 px-4">
      <div class="bg-white px-5 py-5 rounded shadow">
        <h1 class="text-xl">
          Create new questionnaire
        </h1>
        <router-link
          class="green-btn mt-6 inline-block"
          :to="`/projects/${projectId}/questionnaire/create`"
        >
          Create blank questionnaire
        </router-link>

        <div
          v-if="questionnaires && questionnaires.length > 0"
          class="mt-8"
        >
          <h2 class="mb-8 text-lg">
            Or create based on questionnaire template
          </h2>
          <div
            v-for="questionnaire in questionnaires"
            :key="questionnaire.id"
            class="flex items-center mb-3 cursor-pointer border border-solid border-grey-d1 rounded px-4 py-4 hover:bg-grey-lighter"
            @click="loadTemplate(questionnaire, 'questionnaire')"
          >
            <img
              src="@/assets/img/icons/ico-questionnaire.svg"
              width="25"
              class="align-middle"
            >
            <span class="ml-3 mt-1 inline-block">Questionnaire template: {{ questionnaire.name }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import auth from '@/auth'
import { logException } from '@/helpers'
import { createCancelSource } from '@/http'

let cancelSource

export default {
  data(){
    return {
      isSaving: false,
      isSaved: false,
      loading: false,
      project: null,
      projectId: this.$route.params.id,
      questionnaires: {},
      user: auth.user(),
      timezone: '',
    }
  },
  async mounted(){

    this.timezone = this.user.companySettings.timezone
    cancelSource = createCancelSource()

    try {
      const { data } = await this.$api.get('projects').load(this.projectId, {
        cancelToken: cancelSource.token
      })
      this.project = data.project
    } catch(e){
      logException(e)
    }

    try {
      const { data } = await this.$api.get('projects').getProposalByState(this.projectId, 'accepted', {
        cancelToken: cancelSource.token
      })
      this.proposals = data.proposals

    } catch(e){
      logException(e)
    }

    try {
      const { data } = await this.$api.get('templateQuestionnaire').list(this.user.company.id, {
        cancelToken: cancelSource.token
      })
      this.questionnaires = data.questionnaires
    } catch(e){
      logException(e)
    }

  },
  methods: {
    async loadTemplate(item, type){
       try {
        const data = await this.$store.dispatch('template/loadTemplate', item.id)
        if(data == 'success'){
          this.$router.push(`/projects/${this.projectId}/questionnaire/create?load=1`)
        }
      } catch(e) {
        this.$toasted.global.api_error(e)
      }
    }
  }
}
</script>
